import React from 'react';
import ProductSCM from '../containers/ProductSCM';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from '../theme';
import { ResetCSS } from '../assets/css/style';
import GlobalStyle, { AppWrapper, ContentWrapper } from '../containers/global.style';
import SEO from '../components/seo';

export default () => (
  <ThemeProvider theme={theme}>
    <SEO title='Product Overview' />
    <Modal />
    <ResetCSS />
    <GlobalStyle />
    <AppWrapper>
      <ProductSCM />
    </AppWrapper>
  </ThemeProvider>
);
