import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import withLocation from '../../components/withLocation';
import firebase from 'gatsby-plugin-firebase';
import Heading from '../../components/Heading';
import Container from '../../components/Container';
import { useTranslation } from 'react-i18next';
import { FeatureWrapper } from './products.style';
import Fade from 'react-reveal/Fade';
import WorkingBlock from '../../components/WorkingBlock';
import Image from '../../components/Image';
import Text from '../../components/Text';

const ProductSCM = ({ search }) => {
  const { id } = search;
  const [data, setData] = useState(null);
  const [idx, setIdx] = useState(null);
  const { t } = useTranslation('products');
  React.useEffect(() => {
    firebase
      .database()
      .ref('/products/' + id)
      .once('value')
      .then((snapshot) => {
        setData(snapshot.val());
      });
  }, []);

  const items = [
    {
      id: 1,
      image: require('../../assets/images/producer.png'),
      title: t('item1.header'),
      additionalContent: t('item1.description'),
    },
    {
      id: 2,
      image: require('../../assets/images/manufacturer.png'),
      title: t('item2.header'),
      additionalContent: t('item2.description'),
    },
    {
      id: 3,
      image: require('../../assets/images/logistics.png'),
      title: t('item3.header'),
      additionalContent: t('item3.description'),
    },
    {
      id: 4,
      image: require('../../assets/images/retailer.png'),
      title: t('item4.header'),
      additionalContent: t('item4.description'),
    },
    {
      id: 5,
      image: require('../../assets/images/consumer.png'),
      title: t('item5.header'),
      additionalContent: t('item5.description'),
    },
  ];

  const BlockComponent = ({ index }) => {
    switch (index) {
      case 1:
        return (
          <Text
            content={`The ${data.name} was produced in ${data.producer.place} on ${data.producer.date} at ${data.producer.time}`}
          />
        );
      case 2:
        return (
          <Text
            content={`The ${data.name} was manufactured in ${data.manufacturer.place} on ${data.manufacturer.packed} with batch number 
            ${data.manufacturer.batch} at a temperature of ${data.manufacturer.temperature}°C. The date of expiration is ${data.manufacturer.expired} 
            and the shelf life after opening is ${data.manufacturer.shelf}.`}
          />
        );
      case 3:
        return (
          <Text
            content={`The ${data.name} packages were transported from ${data.logistics.p_m.replace('-', ' to ')} on ${
              data.logistics.p_m_date
            }. 
            They were transported from ${data.logistics.m_r.replace('-', ' to ')} on ${data.logistics.m_r_date}. `}
          />
        );
      case 4:
        return (
          <Text
            content={`The ${data.name} was placed on shelf of ${data.retailer.name}, ${data.retailer.location} on ${data.retailer.on_shelf}. 
            It was bought on ${data.retailer.invoice} at a price of ${data.retailer.price} €.`}
          />
        );
      case 5:
        return <Text content={'dfdf'} />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <Heading as='h1' content={`${t('main.id')}: ${id}`} textAlign='center' mt='50 px' />
      <FeatureWrapper>
        {items.map((item) => (
          <Fade up delay={100 * item.id} key={`feature-key${item.id}`}>
            <WorkingBlock
              style={{ '--color': `${item.color}` }}
              icon={
                <Fragment>
                  <div
                    onClick={() => {
                      setIdx(item.id);
                    }}
                  >
                    <Image src={item.image} alt={item.title} height={'80px'} />
                  </div>
                </Fragment>
              }
              iconPosition='left'
              // title={<Heading as='h3' content={item.title} />}
              // description={<Text content={item.description} />}
              additionalContent={<Text content={item.title} />}
            />
          </Fade>
        ))}
      </FeatureWrapper>
      {/* {data ? data.name : 'Loading...'} */}
      <BlockComponent index={idx} />
    </Container>
  );
};

ProductSCM.propTypes = {
  search: PropTypes.object,
};

export default withLocation(ProductSCM);
